import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./components/basic/login/login.component";
import {LayoutComponent} from "./components/basic/layout/layout.component";
import {NotFoundComponent} from "./components/basic/not-found/not-found.component";
import {AuthGuard} from "./guards/auth.guard";
import {OwnerGuard} from "./guards/owner.guard";
import {Role} from "./constants";
import {LoginLayoutComponent} from "./components/basic/login-layout/login-layout.component";
import {EditProductComponent} from "./components/admin/edit-product/edit-product.component";
import {ProfileComponent} from "./components/admin/profile/profile.component";
import { WreamUsersComponent } from './components/users/wream-users/wream-users.component';
import { ViewWreamsByUserComponent } from './components/wreams/view-wreams-by-user/view-wreams-by-user.component';
import { ViewWreamComponent } from './components/wreams/view-wream/view-wream.component';
import { ReservationsByUserComponent } from './components/reservations/reservations-by-user/reservations-by-user.component';
import { ManageAllWreamsComponent } from './components/wreams/manage-all-wreams/manage-all-wreams.component';
import { ManageAllVendorsComponent } from './components/vendors/manage-all-vendors/manage-all-vendors.component';
import { ViewAllProductsComponent } from './components/sponsored-products/view-all-products/view-all-products.component';
import { ViewProductComponent } from './components/sponsored-products/view-product/view-product.component';
import { UserFriendsComponent } from './components/users/user-friends/user-friends.component';
import { EditWreamComponent } from './components/admin/edit-wream/edit-wream.component';
import { EditVendorComponent } from './components/admin/edit-vendor/edit-vendor.component';
import { VendorProductsComponent } from './components/vendors/vendor-products/vendor-products.component';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { ProductWishlistComponent } from './components/sponsored-products/product-wishlist/product-wishlist.component';
import { AnalyticsComponent } from './components/basic/analytics/analytics.component';


const routes: Routes = [
  {
      path: '',
      redirectTo: '/analytics',
      pathMatch: 'full'
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      }
    ]
  },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: {roles: [Role.SUPER_ADMIN, Role.ADMIN_USER]},
    children: [
      {
        path: 'analytics',
        component: AnalyticsComponent,
      },
      {
        path: 'wream-users',
        component: WreamUsersComponent,
      },
      {
        path: 'edit-user/:view/:id',
        component: EditUserComponent
      },
      {
        path: 'view-wreams-by-user/:id',
        component: ViewWreamsByUserComponent
      },
      {
        path: 'view-wream/:id',
        component: ViewWreamComponent
      },
      {
        path: 'reservations-by-user/:id',
        component: ReservationsByUserComponent
      },
      // {
      //   path: 'edit-user/:id',
      //   component: EditUserComponent
      // },
      {
        path: 'manage-all-wreams',
        component: ManageAllWreamsComponent
      },
      {
        path: 'manage-all-venders',
        component: ManageAllVendorsComponent
      },
      {
        path: 'edit-wream/:id',
        component: EditWreamComponent,
      },
      // {
      //   path: 'edit-vendor/:id',
      //   component: EditVendorComponent,
      // },
      {
        path: 'edit-product/:id',
        component: EditProductComponent,
      },
      {
        path: 'all-sponsored-products',
        component: ViewAllProductsComponent
      },
      {
        path: 'user-friends/:id',
        component: UserFriendsComponent
      },
      {
        path: 'view-sponsor-product/:id',
        component: ViewProductComponent
      },
      {
        path: 'vendor-products/:id',
        component: VendorProductsComponent
      },
      {
        path: 'product-wishlist/:id',
        component: ProductWishlistComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      }
    ]
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  },

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabled' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

