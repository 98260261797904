import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ProductService } from "src/app/services/product.service";
import { WreamsService } from "src/app/services/wreams.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-edit-product",
  templateUrl: "./edit-product.component.html",
  styleUrls: ["./edit-product.component.css"],
})
export class EditProductComponent implements OnInit {

  productId: string;
  isWhishlistItem: boolean;
  brandName: string;
  brandId:string;

  currencyList: string[] = [
    "ALL",
    "AFN",
    "ARS",
    "AWG",
    "AUD",
    "AZN",
    "BSD",
    "BBD",
    "BDT",
    "BYR",
    "BZD",
    "BMD",
    "BOB",
    "BAM",
    "BWP",
    "BGN",
    "BRL",
    "BND",
    "KHR",
    "CAD",
    "KYD",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "HRK",
    "CUP",
    "CZK",
    "DKK",
    "DOP",
    "XCD",
    "EGP",
    "SVC",
    "EEK",
    "EUR",
    "FKP",
    "FJD",
    "GHC",
    "GIP",
    "GTQ",
    "GGP",
    "GYD",
    "HNL",
    "HKD",
    "HUF",
    "ISK",
    "INR",
    "IDR",
    "IRR",
    "IMP",
    "ILS",
    "JMD",
    "JPY",
    "JEP",
    "KZT",
    "KPW",
    "KRW",
    "KGS",
    "LAK",
    "LVL",
    "LBP",
    "LRD",
    "LTL",
    "MKD",
    "MYR",
    "MUR",
    "MXN",
    "MNT",
    "MZN",
    "NAD",
    "NPR",
    "ANG",
    "NZD",
    "NIO",
    "NGN",
    "NOK",
    "OMR",
    "PKR",
    "PAB",
    "PYG",
    "PEN",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "RUB",
    "SHP",
    "SAR",
    "RSD",
    "SCR",
    "SGD",
    "SBD",
    "SOS",
    "ZAR",
    "LKR",
    "SEK",
    "CHF",
    "SRD",
    "SYP",
    "TWD",
    "THB",
    "TTD",
    "TRY",
    "TRL",
    "TVD",
    "UAH",
    "GBP",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "YER",
    "ZWD",
  ];

  base64: any[] = [];
  base64_thum: any;
  // uploadPhotosList: any = [];
  // brandName: string;
  productData: any;
  isbrandLoading: boolean = true;

  formData: any = {
    id: null,
    category_id: null,
    brand_id: null,
    title: null,
    description: null,
    price: null,
    url: null,
    currency: null,
    images: null,
    thumbnailImage: null,
  };

  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  editProductForm: FormGroup;

  categoryList: any[];
  brandList: any[] = [];
  allBrands: Observable<any[]>;
  brandControl = new FormControl();
  photoList: any[] = [];

  constructor(
    private productService: ProductService,
    public route: ActivatedRoute,
    private wreamService: WreamsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.editProductForm = new FormGroup({
      category: new FormControl(null, [Validators.required]),
      brand: new FormControl(null, [Validators.required]),
      title: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl(null, [
        Validators.maxLength(300),
        Validators.required,
      ]),
      price: new FormControl(null, [Validators.required]),
      url: new FormControl(null, [
        Validators.maxLength(150),
        Validators.required,
        Validators.pattern(this.urlPattern)
      ]),
      currency: new FormControl(null, [
        Validators.maxLength(150),
        Validators.required,
      ]),
      photos: new FormControl(null),
      // thumbnail: new FormControl(null),
    });

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("id")) {
        this.productId = paramMap.get("id");

        this.wreamService.refreshNeeded.subscribe(() => {
          this.productService
            .getProductDetails(this.productId)
            .subscribe((res) => {
              this.productData = res.data;
              this.assignData(this.productData);
            });
        });
        this.productService
          .getProductDetails(this.productId)
          .subscribe((res) => {
            this.productData = res.data;
            this.assignData(this.productData);
          });
      } else {
        return console.log("Product Can't find");
      }
    });

    this.productService.getAllProductCategories().subscribe((categories) => {
      this.categoryList = categories.data;
    });
  }

  private filterBrand(brand: string): any[] {
    const filterValue = brand.toLowerCase();
    return this.brandList.filter((brandEl) =>
      brandEl.name.toLowerCase().includes(filterValue)
    );
  }

  selectedBrand(brand): void {
    this.brandName = brand;
  }

  selectedBrandId(brandId): void {
    this.brandId= brandId;
  }

  onAddBrand(): void {
    this.formData.brandName = this.brandName;
    this.formData.brand_id = this.brandId;
  }

  onSelectBrand() {
    this.productService.getAllProductBrands().subscribe((brands) => {
      this.brandList = brands.data;
      // console.log(this.brandList)
      this.allBrands = this.brandControl.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterBrand(value))
      );
      this.isbrandLoading = false;
    });
  }

  assignData(product: any) {
    // console.log(product)

    if (product.haveWishlist === true) {
      this.editProductForm.controls['photos'].disable();
    }

    this.isWhishlistItem = product.haveWishlist;
    this.photoList = product.photos;

    this.formData = {
      id: this.productId,
      category_id: product.category ? product.category.id : null,
      brand_id: product.brand ? product.brand.id : null,
      title: product.title,
      brandName: product.brand ? product.brand.name : null,
      description: product.description,
      price: product.price,
      url: product.url,
      currency: product.currency === '$' ? 'USD' : product.currency,
      photos: [],
      // thumbnail: product.thumbnail,
    };
  }

  onFileChange(file): void {
    // console.log(file)
    // for (let i = 0; i < file.length; i++) {
    //   this.uploadPhotosList.push(file[i]);
    // }
  }

  onSaveProduct() {

    //Getting url of the existing photos
    this.photoList.map((photo) => {
      this.formData.photos.push(photo.url);
    });

    //Base64
    if (this.base64) {
      this.base64.map((photo) => {
        console.log(photo);
        this.formData.photos.push(photo.base64);
      });
    }

    // this.formData.thumbnail = this.base64_thum ? this.base64_thum[0].base64 : this.formData.thumbnail;

    // if (this.formData.photos.length > 4) {
    //   console.log('Uploads Too Much')
    // } else {
    //   console.log('ok to proceed')
    // }

    console.log(this.formData);

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't Save this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.updateProductByAdmin(this.formData).subscribe(res => {
          console.log(res)
          if (res.success) {
            Swal.fire(
              {
                position: 'top-end',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
              }
            );
            this.assignData(res.data);
            this.router.navigate(['/all-sponsored-products']);
          } else {
            Swal.fire(res.message);
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your file is safe',
          'error'
        ).then(() => window.location.reload())
      }
    });

  }

  onImage(url: any) {
    window.open(url, "_blank");
  }

  onDeleteImage(id: string) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you won't remove this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.wreamService.DeletePhotoByAdmin(id).subscribe((res) => {
          if (res.success) {
            // this.vendors = res.data
            Swal.fire("Removed!", "Your file has been removed.", "success");
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }
}
