import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WreamsService } from 'src/app/services/wreams.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-view-wream',
  templateUrl: './view-wream.component.html',
  styleUrls: ['./view-wream.component.css']
})
export class ViewWreamComponent implements OnInit {

  id: string;

  wreamData: any = {
    id: null,
    title: null,
    categoryId: null,
    subcategoryId: null,
    description: null,
    price: null,
    images: null,
    address: null,
    shopURL: null,
    status: null,
    currency: null,
    priority: null,
    brand: null,
    thumbnailImage: null
  }


  constructor(
    private route: ActivatedRoute,
    private wreamService: WreamsService,
    private router: Router) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
        this.wreamService.refreshNeeded.subscribe(() => {
          this.getWreamDetails(this.id);
        });
        this.getWreamDetails(this.id);
      } else {
        return console.log("Server Error")
      }
    });
  }

  getWreamDetails(id: string) {
    this.wreamService.getWreamDetails(id).subscribe(
      (res) => {
        console.log(res);
        if (res.success) {

          // if(res.data.brand !== null) {
          //   this.wreamData.brandId = res.data.brand.name;
          // }

          this.wreamData = {
            id: res.data.id,
            title: res.data.title,
            categoryId: res.data.category.name,
            subcategoryId: res.data.category.name,
            description: res.data.description,
            price: res.data.price,
            images: res.data.photos,
            address: res.data.address,
            shopURL: res.data.url_shop,
            status: res.data.status,
            currency: res.data.currency,
            priority: res.data.priority,
            thumbnailImage: res.data.thumbnail,
            brand: res.data.brand ? res.data.brand.name : null
          };
        }
      }
    );
  }

  onUpdateWream(wream: any) {
    this.router.navigateByUrl('edit-wream/' + wream);
  }

  onImage(url: any) {
    window.open(url, '_blank');
  }

  onDeleteWream(wremId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.wreamService.deleteWremById(wremId).subscribe(res => {
          if (res.success) {
            // this.vendors = res.data
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            );
            this.router.navigate(['/manage-all-wreams'])
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }


}

