import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ReservationsService } from "src/app/services/reservations.service";
import { UserService } from "src/app/services/user.service";
import { WreamsService } from "src/app/services/wreams.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-reservations-by-user",
  templateUrl: "./reservations-by-user.component.html",
  styleUrls: ["./reservations-by-user.component.css"],
})
export class ReservationsByUserComponent implements OnInit {
  id: string;
  reservationUserWreamStatus = false;

  dataSource: any;
  searchKey: string;
  userReservedWreamsCount: number;
  reservedUserWreamsCount: number;
  displayedColumns: string[] = ["id", "title", "reservedBy", "action"];
  userName: string;
  userReservedItems: any[] = [];
  reservationUserItems: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    private reservationService: ReservationsService,
    private userService: UserService,
    private router: Router,
    private wreamService: WreamsService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("id")) {
        this.id = paramMap.get("id");

        this.userService.getUserByAdmin(this.id).subscribe((res) => {
          const user: any = res.data;
          const fName: any = user.name;
          const lName: any = user.lastname !== null ? user.lastname : "";
          console.log(user);
          this.userName = `${fName} ${lName}`;
        });

        this.reservationService.refreshNeeded.subscribe(() => {
          this.onReservationUserWreams();
          this.onReservedItems();
        });
        this.onReservationUserWreams();
        this.onReservedItems();
      } else {
        return console.log("Server Error");
      }
    });
  }

  onReservedItems() {
    this.reservationUserWreamStatus = false;
    this.reservationService.getUserReservedItems(this.id).subscribe((res) => {
      console.log(res);

      if (res.success) {
        this.userReservedWreamsCount = res.data.length;
        this.userReservedItems = res.data;
        this.dataSource = new MatTableDataSource(this.userReservedItems);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        return console.log("Error");
      }
    });
  }

  onReservationUserWreams() {
    this.reservationUserWreamStatus = true;
    this.reservationService
      .getReservationUserWreams(this.id)
      .subscribe((res) => {
        console.log(res);

        if (res.success) {
          this.reservedUserWreamsCount = res.data.length;
          this.reservationUserItems = res.data;
          this.dataSource = new MatTableDataSource(this.reservationUserItems);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          return console.log("Error");
        }
      });
  }

  onUpdateWream(wream: any) {
    this.router.navigateByUrl("edit-wream/" + wream.id);
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

  onDeleteReservation(rItem: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you won't remove this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          block: true,
        };
        this.wreamService.deleteWremById(rItem.id).subscribe((res) => {
          if (res.success) {
            Swal.fire("Removed!", "Your file has been removed.", "success");
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }
}
