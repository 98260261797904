import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { WreamsService } from 'src/app/services/wreams.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-wreams-by-user',
  templateUrl: './view-wreams-by-user.component.html',
  styleUrls: ['./view-wreams-by-user.component.css']
})
export class ViewWreamsByUserComponent implements OnInit {

  id: string;
  userWishlistStatus: boolean = false;

  dataSource: any;
  searchKey: string;
  userCreatedWreamCount: number;
  userWishlistWreamCount: number;
  displayedColumns: string[] = ['id', 'title', 'description', 'images', 'thumbnailImage', 'viewWream', 'action'];
  userName: string;

  createdWreams: any[] = [];
  wishlistWreams: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public route: ActivatedRoute, private wreamService: WreamsService, private userService: UserService, private router: Router) { }

  ngOnInit(): void {


    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');

        this.userService.getUserByAdmin(this.id).subscribe((res) => {

          const user: any = res.data;
          const fName: any = user.name;
          const lName: any = user.lastname !== null ? user.lastname : "";
          console.log(user)
          this.userName = `${fName} ${lName}`
        })

        this.wreamService.refreshNeeded.subscribe(() => {
          this.onWishlistWreams();
          this.onCreatedWreams();
        });
        this.onWishlistWreams();
        this.onCreatedWreams();

      } else {
        return console.log("Server Error")
      }
    });

  }

  onCreatedWreams() {
    this.userWishlistStatus = false;
    this.wreamService.getUserWreams(this.id).subscribe(
      (res) => {
        console.log(res)
        if (res.success) {
          this.userCreatedWreamCount = res.data.length;
          this.createdWreams = res.data;
          this.dataSource = new MatTableDataSource(this.createdWreams);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          return console.log("Error")
        }
      }
    )
  }

  onWishlistWreams() {
    this.userWishlistStatus = true;
    this.wreamService.getUSerWishList(this.id).subscribe(
      (res) => {
        console.log(res)
        if (res.success) {
          this.userWishlistWreamCount = res.data.length;
          this.wishlistWreams = res.data;
          this.dataSource = new MatTableDataSource(this.wishlistWreams);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    )
  }

  onUpdateWream(wream: any) {
    this.router.navigateByUrl('edit-wream/' + wream.id);
  }

  onDeleteWream(wream: any) {
    console.log(wream)
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.wreamService.deleteWremById(wream.id).subscribe(res => {
          console.log(res)
          if (res.success) {
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            );
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

  onImage(url: any) {
    window.open(url, '_blank');
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
