import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  error: string;
  status = null;

  constructor(
    private userService: UserService,
    private router: Router) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe(async user => {
      if (user) {
        await this.goHome();
      }
    })
  }

  login() {
    if (!this.email) {
      this.error = "Email cannot be empty";
      return false;
    }
    if (!this.password) {
      this.error = "Password cannot be empty";
      return false;
    }
    this.status = "processing";
    // console.log('DSSSSSSSSSSS');

    this.userService.login(this.email, this.password).subscribe(async res => {
      // console.log(res);
      if (res.success) {
        this.userService.getUser().subscribe(async user => {
          if (user) {
            await this.goHome();
          }
        });
      } else {
        this.status = null;
        this.error = 'Please enter valid email and password';
        return false;
      }
    });
  }

  private async goHome() {
    await this.router.navigate(['/analytics']);
  }
}
