import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ViewAndEdit } from 'src/app/common/ViewAndEdit';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user: any;
  updateUserForm: FormGroup;
  url: string;
  view: boolean;
  viewAndEdit = ViewAndEdit;
  base64Data: any;

  currencyList: string[] = [
    "ALL",
    "AFN",
    "ARS",
    "AWG",
    "AUD",
    "AZN",
    "BSD",
    "BBD",
    "BDT",
    "BYR",
    "BZD",
    "BMD",
    "BOB",
    "BAM",
    "BWP",
    "BGN",
    "BRL",
    "BND",
    "KHR",
    "CAD",
    "KYD",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "HRK",
    "CUP",
    "CZK",
    "DKK",
    "DOP",
    "XCD",
    "EGP",
    "SVC",
    "EEK",
    "EUR",
    "FKP",
    "FJD",
    "GHC",
    "GIP",
    "GTQ",
    "GGP",
    "GYD",
    "HNL",
    "HKD",
    "HUF",
    "ISK",
    "INR",
    "IDR",
    "IRR",
    "IMP",
    "ILS",
    "JMD",
    "JPY",
    "JEP",
    "KZT",
    "KPW",
    "KRW",
    "KGS",
    "LAK",
    "LVL",
    "LBP",
    "LRD",
    "LTL",
    "MKD",
    "MYR",
    "MUR",
    "MXN",
    "MNT",
    "MZN",
    "NAD",
    "NPR",
    "ANG",
    "NZD",
    "NIO",
    "NGN",
    "NOK",
    "OMR",
    "PKR",
    "PAB",
    "PYG",
    "PEN",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "RUB",
    "SHP",
    "SAR",
    "RSD",
    "SCR",
    "SGD",
    "SBD",
    "SOS",
    "ZAR",
    "LKR",
    "SEK",
    "CHF",
    "SRD",
    "SYP",
    "TWD",
    "THB",
    "TTD",
    "TRY",
    "TRL",
    "TVD",
    "UAH",
    "GBP",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "YER",
    "ZWD",
  ];

  formData: any = {
    name : '',
    pwd: '',
    nickname : '',
    lastname: '',
    currency: '',
    private: '',
    birthday: '',
    avatar: ''
  };
  userId: any;
  base64: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.updateUserForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      pwd: [''],
      nickname: ['', [Validators.required, Validators.maxLength(50)]],
      lastname: ['', [Validators.required, Validators.maxLength(70)]],
      currency: ['', [Validators.required, Validators.maxLength(10)]],
      private: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      avatar: ['']
    });

    this.route.paramMap.subscribe(params => {

      if (params.get('view') === this.viewAndEdit.EDIT) {
        this.view = false;
      } else {
        this.view = true;
        this.updateUserForm.controls['name'].disable();
        this.updateUserForm.controls['pwd'].disable();
        this.updateUserForm.controls['nickname'].disable();
        this.updateUserForm.controls['lastname'].disable();
        this.updateUserForm.controls['currency'].disable();
        this.updateUserForm.controls['private'].disable();
        this.updateUserForm.controls['birthday'].disable();
        this.updateUserForm.controls['avatar'].disable();
      }

      this.userId = params.get('id');
      this.userService.getUserByAdmin(this.userId).subscribe(res => {
        this.user = res.data;
        this.assignData(this.user);
      });
  });
  }

  saveUserProfile() {

    this.formData.avatar = this.base64 ? this.base64[0].base64 : this.formData.avatar;

    this.formData.private = this.formData.private === 'false' ? false : true;

    console.log(this.formData)


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't Save this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.updateUserByAdmin(this.userId, this.formData).subscribe(res => {
          // console.log(res)
          if (res.success) {
            Swal.fire(
              {
                position: 'top-end',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
              }
            );
            this.assignData(res.data);
            if (res.data.role === 'vendor') {
              this.router.navigate(['/manage-all-venders']);
            } else {
              this.router.navigate(['/wream-users']);
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your file is safe',
          'error'
        ).then(() => window.location.reload())
      }
    });
  }

  assignData(user) {
    console.log(user)
    this.formData = {
      name: user.name,
      pwd: user.pwd,
      nickname: user.nickname,
      lastname: user.lastname,
      currency: user.currency === '€' ? 'EUR' : user.currency,
      private: +user.private === 0 ? false : true,
      birthday: user.birthday,
      avatar: user.avatar === null ? '' : user.avatar
    };
  }

  onFileChange(event): void {
    let file = event.target.files[0];

    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {

    //   console.log(reader.result);
    // };
    // reader.onerror = function (error) {
    //   console.log('Error: ', error);
    // };
  }

  editUserProfile(user: any, boolVal: Boolean) {
    this.router.navigateByUrl('edit-user/' + boolVal + '/' + user.id);
  }

}
