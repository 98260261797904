export enum Role {
  BRAND_USER = "vendor",
  BRAND_ADMIN = "brand-admin",
  ADMIN_USER = "admin-user",
  SUPER_ADMIN = "admin",
}

export enum VerifyStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
