import {environment} from "../environments/environment";
import {Location} from "@angular/common";


export namespace AppConfig {

  export function apiUrl(paths) {
    return Location.joinWithSlash(environment.api_url, paths);
  }
}

