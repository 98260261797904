import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient) { }

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  public getUserReservedItems(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`reservation/user/${id}/1000`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getReservationUserWreams(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`reservation/user-wishlist/${id}/1000`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public blockReservedItemsById(itemId: number): Observable<any> {
    const ep = AppConfig.apiUrl('wream/delete/' + itemId);
    return this.httpClient.delete<any>(ep).pipe(map(response => response));
  }
}
