import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewAndEdit } from 'src/app/common/ViewAndEdit';
import { VendorService } from 'src/app/services/vendor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-all-vendors',
  templateUrl: './manage-all-vendors.component.html',
  styleUrls: ['./manage-all-vendors.component.css']
})
export class ManageAllVendorsComponent implements OnInit {

  viewAndEdit = ViewAndEdit;
  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = ['id', 'name', 'email', 'viewProfile', 'productCount', 'viewProducts', 'action'];

  vendors: any[] = [];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private vendorService: VendorService,
    private router: Router
    ) { }

  ngOnInit(): void {

    this.vendorService.refreshNeeded.subscribe(() => {
      this.getAllVendors();
    });
    this.getAllVendors();


  }


  getAllVendors() {
    this.vendorService.getAllVendors().subscribe(
      (res) => {
        console.log(res);
        if (res.success) {
          res.data.map(vendor => {
            if (vendor.name !== null) {
              const vendorData: any = {
                id: vendor.id,
                name: vendor.name,
                email: vendor.email,
                productCount: vendor.vendor_products_count
              }
              this.vendors.push(vendorData);
            } else if (vendor.name === null) {
              const vendorData: any = {
                id: vendor.id,
                name: "null",
                email: vendor.email,
                productCount: vendor.vendor_products_count
              }
              this.vendors.push(vendorData);
            }
          });
          this.dataSource = new MatTableDataSource(this.vendors);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    );
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

  onBlockAndUnBlockVendor(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          "block" : true
        };
        this.vendorService.blockVendorById(id, data).subscribe(res => {
          this.vendors = res.data;
          if (res.success) {
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            );
          }  else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

  setUpdateVendorData(vendor: any, boolVal: boolean ) {
    this.router.navigateByUrl('edit-user/' + boolVal + '/' + vendor.id);
  }

  pageChanged(event: any) {
    console.log(event)
    // console.log(isClicked)

    // this.isClickedNext = true;

    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    // if (pageIndex > previousIndex) {
    //   this.getNextData();
    // }
  }

  // getNextData() {
  //   // console.log(this.wreamUsers.length)
  //   let offset = this.vendors.length + 1;
  //   let limit = 1000;
  //   console.log(offset)
  //   this.vendorService.getNextData(limit, offset).subscribe(
  //     (res) => {
  //       // console.log(res);
  //       if (res.success) {

  //         // this.wreamUsers.length = currentSize;

  //         this.vendors.push(...res.data);

  //         let newVendors = new Set(this.vendors);

  //         this.vendors = Array.from(newVendors);

  //         // res.data.forEach(element => {
  //         //   if(!this.wreamUsers.includes(element)) {
  //         //     this.wreamUsers.push(element);
  //         //   }
  //         // })

  //         // this.wreamUsers = this.removeDuplicate(this.wreamUsers);

  //         // console.log(this.wreamUsers)

  //         this.dataSource = new MatTableDataSource(this.vendors);
  //         this.dataSource._updateChangeSubscription();
  //         this.dataSource.paginator = this.paginator;
  //       }
  //     }
  //   );
  // }

}
