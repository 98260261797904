import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { WreamUserService } from 'src/app/services/wream-user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
declare var jQuery
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  logoImg
  user:User

  userData: any = {
    name: null,
    lastName: null,
    email: null,
    role: null,
    birthday: null,
    avatar: null
  }

  userForm: FormGroup;


  constructor(
    private userService: UserService,
    private wreamUserService: WreamUserService,
    private _formBuilder: FormBuilder) {
    // this.userService.getUser().subscribe(user=>{
    //   this.user = user
    // })
  }

  ngOnInit() {

    this.userForm = this._formBuilder.group({
      name: ['',],
      lastname: [''],
      email: [''],
      role: [''],
      birthday: [''],
      avatar: ['']
    });

    this.wreamUserService.getOwnUser().subscribe(
      res => {
        console.log(res)

        this.userData = {
          name: res.name,
          lastName: res.lastname,
          email: res.email,
          role: res.role,
          birthday: res.birthday,
          avatar: res.avatar
        }
      }
    );

}


  //   const input: any = {};
  //   jQuery(document).ready(function(){
  //     jQuery('.clockpicker').clockpicker({
  //     donetext: 'Done'
  //     ,
  //   }).find('input').change(function () {
  //   });
  //   jQuery('#check-minutes').click(function (e) {
  //     // Have to stop propagation here
  //     e.stopPropagation();
  //     input.clockpicker('show').clockpicker('toggleView', 'minutes');
  //   });
  //   if (/mobile/i.test(navigator.userAgent)) {
  //     jQuery('input').prop('readOnly', true);
  //   }
  //   jQuery('.input-daterange-timepicker').daterangepicker({
  //     timePicker: true
  //     , format: 'MM/DD/YYYY h:mm A'
  //     , timePickerIncrement: 30
  //     , timePicker12Hour: true
  //     , timePickerSeconds: false
  //     , buttonClasses: ['btn', 'btn-sm']
  //     , applyClass: 'btn-danger'
  //     , cancelClass: 'btn-inverse'
  //   });
  //   jQuery('.input-limit-datepicker').daterangepicker({
  //     format: 'MM/DD/YYYY'
  //     , minDate: '06/01/2015'
  //     , maxDate: '06/30/2015'
  //     , buttonClasses: ['btn', 'btn-sm']
  //     , applyClass: 'btn-danger'
  //     , cancelClass: 'btn-inverse'
  //     , dateLimit: {
  //       days: 6
  //     }
  //   });
  // });
  // }

}
