import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-wishlist',
  templateUrl: './product-wishlist.component.html',
  styleUrls: ['./product-wishlist.component.css']
})
export class ProductWishlistComponent implements OnInit {


  id: string;
  followersStatus: boolean = false;


  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = ['id', 'avatar', 'name', 'email', 'nickname', 'action'];

  followingList: any[] = [];
  followersList: any[] = [];
  wishlist: any[] = [];



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private productService: ProductService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
        this.productService.refreshNeeded.subscribe(() => {

        });
        
      } else {
        return console.log("Server Error")
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

}
