import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../config';

@Injectable({
  providedIn: 'root'
})
export class WreamUserService {

  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient) { }

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  public getAllWreamUsers(): Observable<any> {
    const ep = AppConfig.apiUrl(`all-users/100/0`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  // User filter routes

  public getFilteredUsers(data: any): Observable<any> {
    const ep = AppConfig.apiUrl(`user-filter`);
    return this.httpClient.post<any>(ep, data).pipe(
      map(
        response => response
      )
    );
  }
  /////////////////////////////////////////////////////////////////////////////////

  public getWreamUsersBySearch(term: string): Observable<any> {
    const ep = AppConfig.apiUrl(`all-users/1000/0/${term}`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getTotalUsers(): Observable<any> {
    const ep = AppConfig.apiUrl(`count/user`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getTotalActiveLoginSessions(): Observable<any> {
    const ep = AppConfig.apiUrl(`count/active-login-session`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getTotalWreamPageVisits(): Observable<any> {
    const ep = AppConfig.apiUrl(`count/wream-visit-users`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getNextData(limit?: number, offset?: number): Observable<any> {
    const ep = AppConfig.apiUrl(`all-users/${limit}/${offset}`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getAllFriends(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`user/friends/${id}/1000`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public blockUserById(userId: number, data: any): Observable<any> {
    const ep = AppConfig.apiUrl('user/block/' + userId);
    return this.httpClient.post<any>(ep, data).pipe(
      map(
        response => response.data
      ),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  public getOwnUser(): Observable<any> {
    const ep = AppConfig.apiUrl('get-own-user');
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response.data
      ),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

}
