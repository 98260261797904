import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginLayoutComponent} from './components/basic/login-layout/login-layout.component';
import {LoginComponent} from './components/basic/login/login.component';
import {LayoutComponent} from './components/basic/layout/layout.component';
import {NavBarComponent} from './components/basic/nav-bar/nav-bar.component';
import {MenuComponent} from './components/basic/menu/menu.component';
import {EditProductComponent} from './components/admin/edit-product/edit-product.component';
import {NotFoundComponent} from './components/basic/not-found/not-found.component';
import {RouterModule} from "@angular/router";
import {FooterComponent} from './components/basic/footer/footer.component';
import {ProfileComponent} from "./components/admin/profile/profile.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule} from "ngx-dropzone-wrapper";
import {AppConfig} from "./config";
import {CustomInterceptor} from "./custom-interceptor";
import {NgxPaginationModule} from "ngx-pagination";
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtModule } from '@auth0/angular-jwt';
import {DatePickerModule} from "@syncfusion/ej2-angular-calendars";
import {NotifierModule} from "angular-notifier";
import {customNotifierOptions} from "./notifier-options";
import { WreamUsersComponent } from './components/users/wream-users/wream-users.component';
import {MatTableModule} from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ViewWreamsByUserComponent } from './components/wreams/view-wreams-by-user/view-wreams-by-user.component';
import { ViewWreamComponent } from './components/wreams/view-wream/view-wream.component';
import { ReservationsByUserComponent } from './components/reservations/reservations-by-user/reservations-by-user.component';
import { ManageAllWreamsComponent } from './components/wreams/manage-all-wreams/manage-all-wreams.component';
import { ManageAllVendorsComponent } from './components/vendors/manage-all-vendors/manage-all-vendors.component';
import { VendorProductsComponent } from './components/vendors/vendor-products/vendor-products.component';
import { ViewAllProductsComponent } from './components/sponsored-products/view-all-products/view-all-products.component';
import { ProductWishlistComponent } from './components/sponsored-products/product-wishlist/product-wishlist.component';
import { UserFriendsComponent } from './components/users/user-friends/user-friends.component';
import { EditWreamComponent } from './components/admin/edit-wream/edit-wream.component';
import { EditVendorComponent } from './components/admin/edit-vendor/edit-vendor.component';
import { ViewProductComponent } from './components/sponsored-products/view-product/view-product.component';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { AnalyticsComponent } from './components/basic/analytics/analytics.component';
import { MatSortModule } from '@angular/material/sort';
import { SelectBrandComponent } from './components/select-brand/select-brand.component';
import {MatSelectModule} from '@angular/material/select';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: AppConfig.apiUrl('upload'),
  maxFiles: 4,
  acceptedFiles: 'image/*',
  headers: {
    "Authorization": "Bearer " + localStorage.getItem('token')
  }
};

export function tokenGetterFunction() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    LoginComponent,
    LayoutComponent,
    NavBarComponent,
    MenuComponent,
    EditProductComponent,
    NotFoundComponent,
    FooterComponent,
    ProfileComponent,
    WreamUsersComponent,
    ViewWreamsByUserComponent,
    ViewWreamComponent,
    ReservationsByUserComponent,
    ManageAllWreamsComponent,
    ManageAllVendorsComponent,
    VendorProductsComponent,
    ViewAllProductsComponent,
    ProductWishlistComponent,
    UserFriendsComponent,
    EditWreamComponent,
    EditVendorComponent,
    ViewProductComponent,
    EditUserComponent,
    AnalyticsComponent,
    SelectBrandComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    HttpClientModule,
    DropzoneModule,
    NgxPaginationModule,
    MatAutocompleteModule,
    AlifeFileToBase64Module,
    JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetterFunction
			}
		}),
    DatePickerModule,
    NotifierModule.withConfig(customNotifierOptions),
    BrowserAnimationsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true},
    {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}
