import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OwnerGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const routePermissions = next.data["roles"] as Array<string>;

    return this.userService.getUser().pipe(map(user => {
      let userRole = user.role;
      let isValidUser = false;
      if (user != null && routePermissions.includes(userRole)) {
        isValidUser = true
      } else {
        this.router.navigateByUrl('/404');
      }
      return isValidUser;
    }));
  }
}
