import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-brand',
  templateUrl: './select-brand.component.html',
  styleUrls: ['./select-brand.component.css']
})
export class SelectBrandComponent implements OnInit {


  @Input() isbrandLoading = '';
  @Input() brandControl = '';
  @Input() brandName = '';
  @Input() allBrands = '';

  @Output() selectedBrand = new EventEmitter<any>();
  @Output() selectedBrandId = new EventEmitter<any>();
  @Output() addBrand = new EventEmitter<any>();
  // @Output() addBrandName = new EventEmitter<any>();
  // @Output() addBrandId = new EventEmitter<any>();



  constructor() { }

  ngOnInit(): void {
  }

  addNewBrand(brand: string) {
    this.selectedBrand.emit(brand);
  }

  addNewBrandId(brandId: string) {
    this.selectedBrandId.emit(brandId);
  }

  onAddBrand() {
    this.addBrand.emit();
    // this.addBrandName.emit(this.brandName);
    // this.addBrandId.emit(this.brandId);
  }

}
