import {Injectable} from '@angular/core';
import {Product} from "../models/product";
import {Observable} from "rxjs/internal/Observable";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config";
import {Photo} from "../models/photo";
import {ChangeStatusRequest} from "../models/request";
import {map, tap} from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient) {
  }

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  // public getProductById(productId: number): Observable<Product | null> {
  //   const ep = AppConfig.apiUrl('brand/product/' + productId);
  //   return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  // }

  // public getProductsForBrand(brandId: number): Observable<Product[]> {
  //   const ep = AppConfig.apiUrl('brand/' + brandId + '/products');
  //   return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  // }

  // public getRecentProducts(): Observable<Product[]> {
  //   const ep = AppConfig.apiUrl('recent-products');
  //   return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  // }

  // public getAllProducts(): Observable<Product[]> {
  //   const ep = AppConfig.apiUrl('products/all');
  //   return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  // }

  // public getPendingProducts(): Observable<Product[]> {
  //   const ep = AppConfig.apiUrl('products/pending');
  //   return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  // }

  // public editProduct(data: EditProductRequest): Observable<Product> {
  //   const ep = AppConfig.apiUrl('brand/product');
  //   return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  // }

  // public changeStatus(data: ChangeStatusRequest): Observable<void> {
  //   const ep = AppConfig.apiUrl('product/verify');
  //   return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  // }

  // public uploadProductImage(fileToUpload: any): Observable<any> {
  //   const ep = AppConfig.apiUrl('upload/product');
  //   const formData: FormData = new FormData();
  //   formData.append('file', fileToUpload);
  //   return this.httpClient.post<any>(ep, formData).pipe(map(response => response.data));
  // }

  // public getWreamPhotoByUrl(file: any): Observable<any> {
  //   console.log(file);
  //   const ep = AppConfig.apiUrl('getWreamPhotoByUrl');
  //   const formData: FormData = new FormData();
  //   formData.append('url', file);
  //   return this.httpClient.post<any>(ep, formData).pipe(map(response => response.data));
  // }

  // Admin Panel
  public getAllSponsoredProducts(): Observable<any> {
    const ep = AppConfig.apiUrl('all-products/10000');
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getTotalProducts(): Observable<any> {
    const ep = AppConfig.apiUrl('count/product');
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getProductDetails(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`product/${id}`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public blockSponsoredProductById(productId: number): Observable<any> {
    const ep = AppConfig.apiUrl('product/delete/' + productId);
    return this.httpClient.delete<any>(ep).pipe(
      map(
        response => response
        ),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  public updateProductByAdmin( data: any): Observable<any> {
    const ep = AppConfig.apiUrl(`brand/product`);
    return this.httpClient.post<any>(ep, data).pipe(
      map(
        response => response
      )
    )
  }

  public getAllProductCategories(): Observable<any>{
    // const ep = AppConfig.apiUrl(`categories/all`);
    const ep = (`https://wreamapp.efitosolutions.com/api/v1/category`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getAllProductBrands(): Observable<any> {
    const ep = (`https://wreamapp.efitosolutions.com/api/v1/brand`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

}

export interface EditProductRequest {
  id?: number,
  brand_id: number,
  category_id: number,
  title: string,
  description: string,
  currency: string,
  price: number,
  photos: Photo[]
  url: string
}
