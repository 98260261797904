import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ViewAndEdit } from "src/app/common/ViewAndEdit";
import { UserService } from "src/app/services/user.service";
import { WreamUserService } from "src/app/services/wream-user.service";

@Component({
  selector: "app-user-friends",
  templateUrl: "./user-friends.component.html",
  styleUrls: ["./user-friends.component.css"],
})
export class UserFriendsComponent implements OnInit {
  id: string;
  followersStatus: boolean = false;
  viewAndEdit = ViewAndEdit;

  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = [
    "id",
    "avatar",
    "name",
    "email",
    "nickname",
    "action",
  ];

  followingList: any[] = [];
  followersList: any[] = [];
  friends: any[] = [];
  userName: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public route: ActivatedRoute,
    private wreamUserService: WreamUserService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("id")) {
        this.id = paramMap.get("id");

        this.userService.getUserByAdmin(this.id).subscribe((res) => {
          const user: any = res.data;
          const fName: any = user.name;
          const lName: any = user.lastname !== null ? user.lastname : "";
          console.log(user);
          this.userName = `${fName} ${lName}`;
        });

        this.wreamUserService.refreshNeeded.subscribe(() => {
          this.getAllFriends(this.id);
        });
        this.getAllFriends(this.id);
      } else {
        return console.log("Server Error");
      }
    });
  }

  getAllFriends(id: string) {
    this.followersStatus = false;
    this.wreamUserService.getAllFriends(id).subscribe((res) => {
      console.log(res);

      if (res.success) {
        this.friends = res.data;
        this.dataSource = new MatTableDataSource(this.friends);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        return console.log("Error");
      }
    });
  }

  onViewFriend(user: any, boolVal: Boolean) {
    this.router.navigateByUrl("edit-user/" + boolVal + "/" + user.id);
  }

  onImage(url: any) {
    window.open(url, "_blank");
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
