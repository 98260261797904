import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AppConfig } from "../config";

@Injectable({
  providedIn: "root",
})
export class WreamsService {
  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient) {}

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  public getAllWreams(): Observable<any> {
    const ep = AppConfig.apiUrl(`all-wreams/100/0}`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  // public getWreamsBySearch(term?: string): Observable<any> {
  //   const ep = AppConfig.apiUrl(`all-wreams/100/0/${term}`);
  //   return this.httpClient.get<any>(ep).pipe(map((response) => response));
  // }

  public getTotalWreams(): Observable<any> {
    const ep = AppConfig.apiUrl("count/wream");
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  public getTotalWishlist(): Observable<any> {
    const ep = AppConfig.apiUrl("count/wishlist");
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  public getNextData(limit?: number, offset?: number): Observable<any> {
    const ep = AppConfig.apiUrl(`all-wreams/${limit}/${offset}`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  public getUserWreams(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`wreams/user/${id}/1000`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  public getUSerWishList(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`wishlist/user/${id}/1000`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  public deleteWremById(wremId: number): Observable<any> {
    // console.log(wremId);
    const ep = AppConfig.apiUrl("wream/delete/" + wremId);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  public getWreamDetails(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`wream/${id}`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response));
  }

  public updateWream(id: string, data: any): Observable<any> {
    const ep = AppConfig.apiUrl(`wream/update/${id}`);
    return this.httpClient.post<any>(ep, data).pipe(
      map((response) => response),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  public DeletePhotoByAdmin(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`photo/delete/${id}`);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  // Wream filter Routes

  public getFilteredWreams(data: any): Observable<any> {
    const ep = AppConfig.apiUrl(`wream-filter`);
    return this.httpClient.post<any>(ep, data).pipe(map((response) => response));
  }
}
