import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ViewAndEdit } from "src/app/common/ViewAndEdit";
import { User } from "src/app/models/user";
import { WreamUserService } from "src/app/services/wream-user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-wream-users",
  templateUrl: "./wream-users.component.html",
  styleUrls: ["./wream-users.component.css"],
})
export class WreamUsersComponent implements OnInit {
  dataSource: any;
  searchKey: string;
  isClickedNext: boolean = false;
  isNextDataFetched: boolean = false;
  isFillter: boolean = false;

  startDate: Date = null;
  endDate: Date = null;
  userName: string = null;

  startDateLabel: any = null;
  endDateLabel: any = null;
  usernameLabel: any = null;
  resultsCount: number = null;

  displayedColumns: string[] = [
    "id",
    "name",
    "email",
    "createdAt",
    "wreams",
    "view-count",
    "reservations",
    "profile",
    "friends",
    "action",
  ];

  wreamUsers: any[] = [];

  viewAndEdit = ViewAndEdit;

  userData: any = {
    id: "",
    userName: "",
    email: "",
    password: "",
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private wreamUserService: WreamUserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.wreamUserService.refreshNeeded.subscribe(() => {
      this.getAllWreamUsers();
    });
    this.getAllWreamUsers();
  }

  customFilter(userName: string, startDate: Date, endDate: Date) {
    // console.log(userName);

    this.startDateLabel = startDate;
    this.endDateLabel = endDate;
    this.usernameLabel = userName;
    this.resultsCount = null;

    this.isFillter = true;

    const filterData: any = {
      startDate: startDate,
      endDate: endDate,
      name: userName,
    };

    this.dataSource = null;

    this.wreamUserService.getFilteredUsers(filterData).subscribe((res) => {
      if (res.success) {
        this.wreamUsers = res.data;
        this.dataSource = new MatTableDataSource(this.wreamUsers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resultsCount = res.data.length;
      } else {
        Swal.fire(res.message).then(() => {
          this.getAllWreamUsers();
          this.isFillter = false;
        });
      }
    });

    // if (userName != null && startDate == null && endDate == null) {
    //   this.wreamUserService
    //     .getFilteredUsersByName(userName)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         this.wreamUsers = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreamUsers);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.userName = null;
    //     });
    // } else if (userName == null && startDate != null && endDate != null) {
    //   this.wreamUserService
    //     .getFilteredUsersByDate(startDate, endDate)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreamUsers = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreamUsers);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startDate = null;
    //       this.endDate = null;
    //     });
    // } else if (userName != null && startDate != null && endDate != null) {
    //   this.wreamUserService
    //     .getFilteredUsersByAll(startDate, endDate, userName)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreamUsers = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreamUsers);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.userName = null;
    //       this.startDate = null;
    //       this.endDate = null;
    //     });
    // } else {
    //   this.getAllWreamUsers();
    //   this.isFillter = false;

    //   this.startDateLabel = null;
    //   this.endDateLabel = null;
    //   this.usernameLabel = null;
    //   this.resultsCount = null;
    // }
    // this.userName = null;
    // this.startDate = null;
    // this.endDate = null;
  }

  refreashPage() {
    this.getAllWreamUsers();
    this.isFillter = false;

    this.startDateLabel = null;
    this.endDateLabel = null;
    this.usernameLabel = null;
    this.resultsCount = null;

    this.startDate = null;
    this.endDate = null;
    this.userName = null;
  }

  getAllWreamUsers() {
    this.wreamUserService.getAllWreamUsers().subscribe((res) => {
      // console.log(res);
      if (res.success) {
        this.wreamUsers = res.data;
        this.dataSource = new MatTableDataSource(this.wreamUsers);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  // onUpdateWreamUser(user: User, boolVal: Boolean) {
  //   this.router.navigateByUrl('edit-user/' + user.id);
  // }

  onUserProfile(user: any, boolVal: Boolean) {
    this.router.navigateByUrl("edit-user/" + boolVal + "/" + user.id);
  }

  onDeleteWreamUser(user: User) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you won't remove this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          block: true,
        };
        this.wreamUserService.blockUserById(user.id, data).subscribe((res) => {
          // console.log(res)
          if ((res = [])) {
            Swal.fire("Removed!", "Your file has been removed.", "success");
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

  // applyFilter(value: string) {
  //   this.dataSource.filter = this.searchKey.trim().toLowerCase();
  // }

  // serversideFilter(value: string) {
  //   if (this.searchKey !== "") {
  //     // console.log(value);
  //     this.wreamUserService.getWreamUsersBySearch(value).subscribe((res) => {
  //       if (res.success) {
  //         this.wreamUsers = res.data;
  //         this.dataSource = new MatTableDataSource(this.wreamUsers);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //       }
  //     });
  //   } else {
  //     this.getAllWreamUsers();
  //   }
  // }

  pageChanged(event: any) {
    // console.log(event);
    // console.log(isClicked)

    // this.isClickedNext = true;

    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    // console.log("filter", this.isFillter);

    if (this.isFillter) {
      return;
    }

    if (pageIndex > previousIndex && this.isNextDataFetched === false) {
      this.getNextData();
      this.isNextDataFetched = true;
    }
  }

  getNextData() {
    // console.log(this.wreamUsers.length)
    let offset = this.wreamUsers.length;
    let limit = 100;
    // console.log(offset)
    this.wreamUserService.getNextData(limit, offset).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        this.wreamUsers.push(...res.data);

        let newWreamUsers = new Set(this.wreamUsers);

        this.wreamUsers = Array.from(newWreamUsers);

        this.dataSource = new MatTableDataSource(this.wreamUsers);
        this.dataSource._updateChangeSubscription();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isNextDataFetched = false;
      }
    });
  }
}
