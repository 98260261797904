import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { VendorService } from 'src/app/services/vendor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-products',
  templateUrl: './vendor-products.component.html',
  styleUrls: ['./vendor-products.component.css']
})
export class VendorProductsComponent implements OnInit {

  id: string;

  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = [
    'id',
    'title',
    'description',
    'images',
    'action'];

    vendorProducts: any[] = [];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private vendorService: VendorService,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService) { }

  ngOnInit(): void {


    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
        this.productService.refreshNeeded.subscribe(() => {
          this.getVendorProducts(this.id);
        });
        this.getVendorProducts(this.id);
      } else {
        return console.log("Server Error")
      }
    });
  }


  getVendorProducts(id: string) {
    this.vendorService.getVendorProducts(id).subscribe(
      (res) => {
        console.log(res)
        if (res.success) {
          this.vendorProducts = res.data;
          this.dataSource = new MatTableDataSource(this.vendorProducts);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }

      }
    );
  }

  onImage(url: any) {
    window.open(url, '_blank');
  }

  onEditProduct(product: any) {
    this.router.navigate([`/edit-product/${product.id}`])
  }

  onDeleteProduct(sProduct: any) {
    console.log(sProduct)
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.blockSponsoredProductById(sProduct.id).subscribe(res => {
          console.log(res)
          if (res.success) {
            // this.products = res.data
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            );
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
