import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import Swal from 'sweetalert2';
import {ProductService} from "../../../services/product.service";


@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {


  id: string;

  productsData: any = {
    id: null,
    title: null,
    description: null,
    brandName: null,
    vendorName: null,
    price: null,
    currency: null,
    url: null,
    images: null,
    thumbnailImage: null
  }


  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
        this.productService.refreshNeeded.subscribe(() => {
          this.getProductDetails(this.id);
        });
        this.getProductDetails(this.id);
      } else {
        return console.log("Server Error")
      }
    });
  }


  getProductDetails(id: string) {
    this.productService.getProductDetails(id).subscribe(
      (res) => {
        console.log(res);
        if (res.success) {
          this.productsData = {
            id: res.data.id,
            title: res.data.title,
            description: res.data.description,
            vendorName: res.data.vendor_name,
            price: res.data.price,
            currency: res.data.currency,
            url: res.data.url,
            images: res.data.photos,
            thumbnailImage: res.data.thumbnail,
            brandName: res.data.brand ? res.data.brand.name : null
          };
        }

        // console.log(this.productsData);
      }
    );
  }

  onImage(url: any) {
    window.open(url, '_blank');
  }

  onUpdateProduct(productId: any) {
    this.router.navigateByUrl('edit-product/' + productId);
  }

  onDeleteSponsoredProduct(productId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.blockSponsoredProductById(productId).subscribe(res => {
          if (res.success) {
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            ).then(() => this.router.navigate(['/all-sponsored-products']))
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

}

