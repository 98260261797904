import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-all-products',
  templateUrl: './view-all-products.component.html',
  styleUrls: ['./view-all-products.component.css']
})
export class ViewAllProductsComponent implements OnInit {


  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = [
    'id',
    'title',
    'description',
    'images',
    'thumbnailImage',
    'wishlist',
    'viewProduct',
    'action'];

  products: any[] = [];



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private productService: ProductService,
    private router: Router
    ) { }

  ngOnInit(): void {

    this.productService.refreshNeeded.subscribe(() => {
      this.getAllSponsoredProducts();
    });
    this.getAllSponsoredProducts();

  }

  getAllSponsoredProducts() {
    this.productService.getAllSponsoredProducts().subscribe(
      (res) => {
        // console.log(res.data)
        if (res.success) {
          this.products = res.data;
          this.dataSource = new MatTableDataSource(this.products);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    );
  }

  onDeleteSponsoredProduct(sProducts: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.blockSponsoredProductById(sProducts.id).subscribe(res => {
          if (res.success) {
            this.products = res.data
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            );
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

  onUpdateProduct(product: any) {
    this.router.navigateByUrl('edit-product/' + product.id);

  }

  onImage(url: any) {
    window.open(url, '_blank');
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

}
