import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { UserService } from "./../../../services/user.service";
import { Role } from "src/app/constants";
import { WreamsService } from "src/app/services/wreams.service";
import { WreamUserService } from "src/app/services/wream-user.service";
import { ProductService } from "src/app/services/product.service";
import { ViewAndEdit } from "src/app/common/ViewAndEdit";

export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  age: number;
  gender: string;
}

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.css"],
})
export class AnalyticsComponent implements OnInit {
  searchKey: string;
  displayedColumns: string[] = ["id", "profilePic", "name", "email", "profile"];
  dataSource: any;
  viewAndEdit = ViewAndEdit;

  totalUsers: number;
  totalWreams: number;
  totalProducts: number;
  totalWishlists: number;
  totalActiveLoginSessions: number;
  totalWreamPageVisits: number;
  time: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private wreamUserService: WreamUserService,
    private wreamService: WreamsService,
    private productService: ProductService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.wreamUserService.getTotalUsers().subscribe((res) => {
      this.totalUsers = res.data;
    });

    this.wreamService.getTotalWreams().subscribe((res) => {
      this.totalWreams = res.data;
    });

    this.productService.getTotalProducts().subscribe((res) => {
      this.totalProducts = res.data;
    });

    this.wreamService.getTotalWishlist().subscribe((res) => {
      this.totalWishlists = res.data;
    });

    this.wreamUserService.getTotalActiveLoginSessions().subscribe((res) => {
      this.totalActiveLoginSessions = res.data;
    });

    this.wreamUserService.getTotalWreamPageVisits().subscribe((res) => {
      this.totalWreamPageVisits = res.data;
    });

    this.time = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

    this.getAllUsers();
  }

  getAllUsers() {
    this.wreamUserService.getAllWreamUsers().subscribe((res) => {
      console.log(res);
      if (res.success) {
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  onUserProfile(user: any, boolVal: Boolean) {
    console.log(user)
    this.router.navigateByUrl('edit-user/' + boolVal + '/' + user.id);
  }

  onImage(url: any) {
    window.open(url, "_blank");
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

  // setupFilter(name: string, email: string) {
  //   this.dataSource.filterPredicate = (d: any, filter: string) => {
  //     const textToSearch =
  //       (d[name] &&
  //         d[email] &&
  //         d[name].toLowerCase() &&
  //         d[email].toLowerCase()) ||
  //       "";
  //     console.log(textToSearch);
  //     return textToSearch.indexOf(filter) !== -1;
  //   };
  // }
}
