import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { WreamsService } from 'src/app/services/wreams.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-wream',
  templateUrl: './edit-wream.component.html',
  styleUrls: ['./edit-wream.component.css']
})
export class EditWreamComponent implements OnInit {

  id: string;
  categoryList: any[];
  isWhishlistItem: boolean;
  brandName: string;
  brandId:string;
  currencyList: string[] = [
    'ALL',
    'AFN',
    'ARS',
    'AWG',
    'AUD',
    'AZN',
    'BSD',
    'BBD',
    'BDT',
    'BYR',
    'BZD',
    'BMD',
    'BOB',
    'BAM',
    'BWP',
    'BGN',
    'BRL',
    'BND',
    'KHR',
    'CAD',
    'KYD',
    'CLP',
    'CNY',
    'COP',
    'CRC',
    'HRK',
    'CUP',
    'CZK',
    'DKK',
    'DOP',
    'XCD',
    'EGP',
    'SVC',
    'EEK',
    'EUR',
    'FKP',
    'FJD',
    'GHC',
    'GIP',
    'GTQ',
    'GGP',
    'GYD',
    'HNL',
    'HKD',
    'HUF',
    'ISK',
    'INR',
    'IDR',
    'IRR',
    'IMP',
    'ILS',
    'JMD',
    'JPY',
    'JEP',
    'KZT',
    'KPW',
    'KRW',
    'KGS',
    'LAK',
    'LVL',
    'LBP',
    'LRD',
    'LTL',
    'MKD',
    'MYR',
    'MUR',
    'MXN',
    'MNT',
    'MZN',
    'NAD',
    'NPR',
    'ANG',
    'NZD',
    'NIO',
    'NGN',
    'NOK',
    'OMR',
    'PKR',
    'PAB',
    'PYG',
    'PEN',
    'PHP',
    'PLN',
    'QAR',
    'RON',
    'RUB',
    'SHP',
    'SAR',
    'RSD',
    'SCR',
    'SGD',
    'SBD',
    'SOS',
    'ZAR',
    'LKR',
    'SEK',
    'CHF',
    'SRD',
    'SYP',
    'TWD',
    'THB',
    'TTD',
    'TRY',
    'TRL',
    'TVD',
    'UAH',
    'GBP',
    'USD',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'YER',
    'ZWD'
  ];

  wreamData: any;
  base64_1: any;
  base64_2: any;
  base64_3: any;
  base64_4: any;
  base64_thum: any;
  isbrandLoading: boolean = true;
  uploadPhotosList: any[];

  formData: any = {
    title: null,
    category_id: null,
    subcategory_id: null,
    brand_id: null,
    address: null,
    lat: null,
    lng: null,
    url_shop: null,
    description: null,
    currency: null,
    price: null,
    priority: null,
    image: null,
    thumbnail: null
  }

  // Testing
  image1: any = null;
  image2: any = null;
  image3: any = null;
  image4: any = null;

  images: any = [];

  brandList: any[];
  allBrands: Observable<any[]>;

  brandControl = new FormControl();
  editWreamForm: FormGroup;

  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  constructor(
    private wreamService: WreamsService,
    public route: ActivatedRoute,
    private router: Router,
    private productService: ProductService) { }



    ngOnInit(): void {

    this.editWreamForm = new FormGroup({
      title: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      address:new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      category: new FormControl(null, Validators.required),
      lat: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      lng: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      subcategory: new FormControl(null, Validators.required),
      shopUrl: new FormControl(null, [Validators.required, Validators.maxLength(100), Validators.pattern(this.urlPattern)]),
      description: new FormControl(null, [Validators.required, Validators.maxLength(300)]),
      currency: new FormControl(null, Validators.required),
      price: new FormControl(null, [Validators.required, Validators.maxLength(10)]), // Not working
      priority: new FormControl(null, Validators.required),
      photos: new FormControl(null),
      brand: new FormControl(null, Validators.required),
      // thumbnail: new FormControl(null),
    });


    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');

        this.wreamService.refreshNeeded.subscribe(() => {
          this.wreamService.getWreamDetails(this.id).subscribe(res => {
            this.wreamData = res.data;
            this.assignData(this.wreamData);
          });
        });
        this.wreamService.getWreamDetails(this.id).subscribe(res => {
          this.wreamData = res.data;
          this.assignData(this.wreamData);
        });

      } else {
        return console.log('Wream Can\'t find');
      }
    });

    this.productService.getAllProductCategories().subscribe(
      categories => {
        this.categoryList = categories.data
      }
    );

  }

  private filterBrand(brand: string): any[] {
    const filterValue = brand.toLowerCase();
    return this.brandList.filter((brandEl) =>
      brandEl.name.toLowerCase().includes(filterValue)
    );
  }

  selectedBrand(brand): void {
    this.brandName = brand;
  }

  selectedBrandId(brandId): void {
    this.brandId= brandId;
  }

  onAddBrand(): void {
    this.formData.brandName = this.brandName;
    this.formData.brand_id = this.brandId;
  }


  assignData(wream: any) {
    console.log(wream)

    if (wream.haveWishlist === true) {
      this.editWreamForm.controls['photos'].disable();
    }

    this.isWhishlistItem = wream.haveWishlist;

    this.formData = {
      title: wream.title,
      address: wream.address,
      category_id: wream.category.id,
      brandName: wream.brand ? wream.brand.name : null,
      lat: wream.lat,
      lng: wream.lng,
      subcategory_id: wream.category.id,
      url_shop: wream.url_shop,
      description: wream.description,
      currency: wream.currency === '€' ? 'EUR' : wream.currency,
      price: wream.price,
      priority: wream.priority,
      image1: wream.photos[0] ? wream.photos[0].url : null,
      image2: wream.photos[1] ? wream.photos[1].url : null,
      image3: wream.photos[2] ? wream.photos[2].url : null,
      image4: wream.photos[3] ? wream.photos[3].url : null,
      brand_id: wream.brand_id,
      // thumbnail: wream.thumbnail
    };


    if (wream.photos[0]) {
      this.image1 = wream.photos[0];
    }

    if (wream.photos[0]) {
      this.image2 = wream.photos[1];
    }

    if (wream.photos[1]) {
      this.image3 = wream.photos[2];
    }

    if (wream.photos[2]) {
      this.image4 = wream.photos[3];
    }

  }

  onSelectBrand() {
    this.productService.getAllProductBrands().subscribe((brands) => {
      this.brandList = brands.data;
      // console.log(this.brandList)
      this.allBrands = this.brandControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterBrand(value))
      );
      this.isbrandLoading = false;
    });
  }

  onFileChange(file): void {
    for (let i = 0; i < file.length; i++) {
      this.uploadPhotosList.push(file[i]);
    }
  }


  onSaveWream() {

    this.formData.image1 = this.base64_1 ? this.base64_1[0].base64 : this.formData.image1;
    this.formData.image2 = this.base64_2 ? this.base64_2[0].base64 : this.formData.image2;
    this.formData.image3 = this.base64_3 ? this.base64_3[0].base64 : this.formData.image3;
    this.formData.image4 = this.base64_4 ? this.base64_4[0].base64 : this.formData.image4;
    // this.formData.thumbnail = this.base64_thum ? this.base64_thum[0].base64 : this.formData.thumbnail;


    // console.log(this.brandName)

    // this.formData.brand_id = this.formData.brandName === "" ? null : this.formData.brand_id;

    console.log(this.formData);



    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't Save this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.wreamService.updateWream(this.id, this.formData).subscribe(res => {
          // console.log(res)
          if (res.success) {
            Swal.fire(
              {
                position: 'top-end',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
              }
            );
            this.router.navigate(['/manage-all-wreams']);
          } else {
            Swal.fire(res.message);
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your file is safe',
          'error'
        ).then(() => window.location.reload())
      }
    });

  }

  onImage(url: any) {
    window.open(url, '_blank');
  }

  onDeleteImage(id: string) {

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you won't remove this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.wreamService.DeletePhotoByAdmin(id).subscribe(res => {
          if (res.success) {
            // this.vendors = res.data
            Swal.fire(
              'Removed!',
              'Your file has been removed.',
              'success'
            );
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }
}
