import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ViewAndEdit } from "src/app/common/ViewAndEdit";
import { WreamsService } from "src/app/services/wreams.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-manage-all-wreams",
  templateUrl: "./manage-all-wreams.component.html",
  styleUrls: ["./manage-all-wreams.component.css"],
})
export class ManageAllWreamsComponent implements OnInit {
  viewAndEdit = ViewAndEdit;
  dataSource: any;
  searchKey: string;
  isFillter: boolean = false;

  startPrice: number = null;
  endPrice: number = null;
  priority: string = null;
  title: string = null;
  startDate: any = null;
  endDate: any = null;

  startPriceLabel: any = null;
  endPriceLabel: any = null;
  priorityLabel: string = null;
  titleLabel: string = null;
  startDateLabel: any = null;
  endDateLabel: any = null;
  resultsCount: number = null;

  isClickedNext: boolean = false;
  isNextDataFetched: boolean = false;
  priorityOptionsList: any[] = [
    { value: "Low", viewValue: "Low" },
    { value: "Medium", viewValue: "Medium" },
    { value: "High", viewValue: "High" },
  ];
  displayedColumns: string[] = [
    "id",
    "title",
    "user",
    "images",
    "thumbnailImage",
    "wishlist",
    "priority",
    "price",
    "created_at",
    "view",
    "view-count",
    "action",
  ];
  // viewAndEdit = ViewAndEdit;
  wreams: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private wreamService: WreamsService, private router: Router) {}

  ngOnInit(): void {
    this.wreamService.refreshNeeded.subscribe(() => {
      this.getAllWreams();
    });
    this.getAllWreams();
  }

  customFilter(
    priority?: string,
    startPrice?: number,
    endPrice?: number,
    title?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    this.startDateLabel = startDate;
    this.endDateLabel = endDate;
    this.startPriceLabel = startPrice;
    this.endPriceLabel = endPrice;
    this.priorityLabel = priority;
    this.titleLabel = title;
    this.resultsCount = null;

    this.isFillter = true;

    const filterData: any = {
      startDate: startDate,
      endDate: endDate,
      startPrice: startPrice,
      endPrice: endPrice,
      priority: priority,
      title: title,
    };

    // console.log("filterData", filterData)

    this.dataSource = null;

    this.wreamService.getFilteredWreams(filterData).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        this.wreams = res.data;
        this.dataSource = new MatTableDataSource(this.wreams);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resultsCount = res.data.length;
      } else {
        Swal.fire(res.message).then(() => {
          this.getAllWreams();
          this.isFillter = false;
        });
      }
    });

    // if (
    //   title != null &&
    //   startPrice == null &&
    //   endPrice == null &&
    //   priority == null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService.getFilteredWreamsByTitle(title).subscribe((res) => {
    //     if (res.success) {
    //       // console.log(res);
    //       this.wreams = res.data;
    //       this.dataSource = new MatTableDataSource(this.wreams);
    //       this.dataSource.paginator = this.paginator;
    //       this.dataSource.sort = this.sort;
    //       this.resultsCount = res.data.length;
    //     }
    //     this.title = null;
    //   });
    // } else if (
    //   title == null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority == null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByPrice(startPrice, endPrice)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startPrice = null;
    //       this.endPrice = null;
    //     });
    // } else if (
    //   title == null &&
    //   startPrice == null &&
    //   endPrice == null &&
    //   priority != null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByPriority(priority)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.priority = null;
    //     });
    // } else if (
    //   title == null &&
    //   startPrice == null &&
    //   endPrice == null &&
    //   priority == null &&
    //   startDate != null &&
    //   endDate != null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByDateRange(startDate, endDate)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startDate = null;
    //       this.endDate = null;
    //     });
    // } else if (
    //   title != null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority == null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByTitleAndPrice(title, startPrice, endPrice)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.title = null;
    //       this.startPrice = null;
    //       this.endPrice = null;
    //     });
    // } else if (
    //   title != null &&
    //   startPrice == null &&
    //   endPrice == null &&
    //   priority != null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByTitleAndPriority(title, priority)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startDate = null;
    //       this.endDate = null;
    //     });
    // } else if (
    //   title == null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority != null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByPricePriority(priority, startPrice, endPrice)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startPrice = null;
    //       this.endPrice = null;
    //       this.priority = null;
    //     });
    // } else if (
    //   title != null &&
    //   startPrice == null &&
    //   endPrice == null &&
    //   priority == null &&
    //   startDate != null &&
    //   endDate != null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByDateTitle(title, startDate, endDate)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startDate = null;
    //       this.endDate = null;
    //       this.title = null;
    //     });
    // } else if (
    //   title == null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority == null &&
    //   startDate != null &&
    //   endDate != null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByDatePrice(startPrice, endPrice, startDate, endDate)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startDate = null;
    //       this.endDate = null;
    //       this.startPrice = null;
    //       this.endPrice = null;
    //     });
    // } else if (
    //   title == null &&
    //   startPrice == null &&
    //   endPrice == null &&
    //   priority != null &&
    //   startDate != null &&
    //   endDate != null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByDatePriority(priority, startDate, endDate)
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.startDate = null;
    //       this.endDate = null;
    //       this.priority = null;
    //     });
    // } else if (
    //   title != null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority != null &&
    //   startDate == null &&
    //   endDate == null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByTitlePricePriority(
    //       title,
    //       priority,
    //       startPrice,
    //       endPrice
    //     )
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.title = null;
    //       this.startPrice = null;
    //       this.endPrice = null;
    //       this.priority = null;
    //     });
    // } else if (
    //   title != null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority != null &&
    //   startDate != null &&
    //   endDate != null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByAll(
    //       title,
    //       priority,
    //       startPrice,
    //       endPrice,
    //       startDate,
    //       endDate
    //     )
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.title = null;
    //       this.startPrice = null;
    //       this.endPrice = null;
    //       this.priority = null;
    //       this.startDate = null;
    //       this.endDate = null;
    //     });
    // } else if (
    //   title == null &&
    //   startPrice != null &&
    //   endPrice != null &&
    //   priority != null &&
    //   startDate != null &&
    //   endDate != null
    // ) {
    //   this.wreamService
    //     .getFilteredWreamsByDatePriorityPrice(
    //       priority,
    //       startPrice,
    //       endPrice,
    //       startDate,
    //       endDate
    //     )
    //     .subscribe((res) => {
    //       if (res.success) {
    //         // console.log(res);
    //         this.wreams = res.data;
    //         this.dataSource = new MatTableDataSource(this.wreams);
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //         this.resultsCount = res.data.length;
    //       }
    //       this.title = null;
    //       this.startPrice = null;
    //       this.endPrice = null;
    //       this.priority = null;
    //       this.startDate = null;
    //       this.endDate = null;
    //     });
    // } else {
    //   this.getAllWreams();
    //   this.isFillter = false;

    //   this.titleLabel = null;
    //   this.priorityLabel = null;
    //   this.startPriceLabel = null;
    //   this.endPriceLabel = null;
    //   this.startDateLabel = null;
    //   this.endDateLabel = null;
    //   this.resultsCount = null;
    // }
  }

  refreashPage() {
    this.getAllWreams();
    this.isFillter = false;

    this.titleLabel = null;
    this.priorityLabel = null;
    this.startPriceLabel = null;
    this.endPriceLabel = null;
    this.startDateLabel = null;
    this.endDateLabel = null;
    this.resultsCount = null;

    this.startPrice = null;
    this.endPrice = null;
    this.priority = null;
    this.title = null;
    this.startDate = null;
    this.endDate = null;
  }

  getAllWreams() {
    this.wreamService.getAllWreams().subscribe((res) => {
      // console.log(res);
      if (res.success) {
        // this.isLoadingData = false;
        this.wreams = res.data;
        this.dataSource = new MatTableDataSource(this.wreams);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  onUpdateWream(wream: any) {
    this.router.navigateByUrl("edit-wream/" + wream.id);
  }

  onDeleteWream(wrem: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you won't remove this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.wreamService.deleteWremById(wrem.id).subscribe((res) => {
          if (res.success) {
            // this.vendors = res.data
            Swal.fire("Removed!", "Your file has been removed.", "success");
          } else {
            Swal.fire(res.message);
          }
        });
      }
    });
  }

  onUpdateWreamUser(wream: any, boolVal: Boolean) {
    this.router.navigateByUrl("edit-user/" + boolVal + "/" + wream.user_id);
  }

  onImage(url: any) {
    window.open(url, "_blank");
  }

  pageChanged(event: any) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;
    let previousSize = pageSize * pageIndex;

    // console.log("filter", this.isFillter);

    if (this.isFillter) {
      return;
    }

    if (pageIndex > previousIndex && this.isNextDataFetched === false) {
      this.getNextData();
      this.isNextDataFetched = true;
    }
  }

  getNextData() {
    let offset = this.wreams.length;
    // console.log(offset);
    let limit = 100;
    this.wreamService.getNextData(limit, offset).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        this.wreams.push(...res.data);

        let newWreams = new Set(this.wreams);

        this.wreams = Array.from(newWreams);

        this.dataSource = new MatTableDataSource(this.wreams);
        this.dataSource._updateChangeSubscription();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isNextDataFetched = false;
      }
    });
  }
}
