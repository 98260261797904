import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../config';

@Injectable({
  providedIn: 'root'
})
export class VendorService {


  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient) { }

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  public getAllVendors(): Observable<any> {
    const ep = AppConfig.apiUrl('all-vendors/10000');
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  // public getNextData(limit?: number, offset?: number): Observable<any> {
  //   const ep = AppConfig.apiUrl(`all-vendors/${limit}/${offset}`);
  //   return this.httpClient.get<any>(ep).pipe(
  //     map(
  //       response => response
  //     )
  //   );
  // }

  public blockVendorById(vendorId: number, data: any): Observable<any> {
    const ep = AppConfig.apiUrl('user/block/' + vendorId);
    return this.httpClient.post<any>(ep, data).pipe(
      map(response => response
      ),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  public getVendorProducts(id: string): Observable<any> {
    const ep = AppConfig.apiUrl(`products/vendor/${id}`);
    return this.httpClient.get<any>(ep).pipe(
      map(
        response => response
      )
    );
  }

  public getVendorByAdmin(vendorId) {
    const ep = AppConfig.apiUrl('vendor/' + vendorId);
    return this.httpClient.get<any>(ep).pipe(map(response => response));
  }

  public updateVendorByAdmin(vendorId, data: any): Observable<any> {
    const ep = AppConfig.apiUrl('user/update/' + vendorId);
    return this.httpClient.post<any>(ep, data).pipe(map(response => response));
  }
}
